.password-toggle {
  display: flex;
  align-items: center;
}
.password-input {
  border: none !important;
  background-color: transparent !important;
}
.toggle-button {
  width: 20px;
  height: 20px;
  cursor: pointer;
}