.order-details {
  padding: 10px 0px;
}
.toggle-button {
  font-size: 14px;
  font-weight: 500;
  margin-left: 20px;
  background-color: #144166;
  color: white;
  border-radius: 6px;
  height: max-content;
  padding: 3px 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90px;
  cursor: pointer;
}
.row {
  display: flex;
  align-items: center;
  margin: 3px 0px;
}
.info-card {
  /* background-color: rgb(245,245,245); */
  border-radius: 8px;
  padding: 15px;
  margin: 10px 0px;
}
.field {
  font-weight: 500;
  color: rgb(120,120,120);
  min-width: 200px;
}
.value {
  font-weight: 500;
  color: #144166;
  min-width: 200px;
}
