.row {
  display: flex;
  align-items: center;
  margin: 3px 0px;
}
.field {
  font-weight: 500;
  color: rgb(120,120,120);
  min-width: 200px;
}
.value {
  font-weight: 500;
  color: #144166;
  min-width: 200px;
}
.refund_box{
  background-color: #eaeaea;
  border-radius: 8px;
  padding: 15px 25px;
  margin-bottom: 15px;
}

.refund_title{
  font-size: 20px;
  font-weight: 800;
  border-bottom: 3px solid black;
  padding-bottom: 5px;
}
.refund_buttons{
  display: flex;
  padding: 10px 0px;
  width: 100%;
  justify-content: space-around;
}
.refund_prompt_buttons{
  display: flex;
  padding: 10px 0px;
  width: 100%;
  justify-content: center;
}
.refund_prompt{
  margin: 0 auto;
  font-weight: 600;
  text-align: center;
  border-bottom: 3px solid black;
  width: 30%;
}
.refund_option{
  width: 100%;
  padding: 5px 10px;
}
.refund_option Button{
  width: 100%;
}
.refund_secondary_title{
  font-size: 15px;
  font-weight: 600;
  padding: 5px 0px;
  margin-bottom: 20px;
}
.redund_note{
  font-size: 15px;
  font-weight: 600;
  color: red;
  padding: 5px 0px;
}
.refund_reason{
  width: 100%;
  height: 80px;
}
.partial_refund_option{
  font-size: 12px;
  display: flex;
  margin: 5px 0px;
  align-items: center;
}