.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50vh;
}
.form-container {
  padding: 10px;
  width: max-content;
  border: 1px solid rgb(250,250,250);
  border-radius: 10px; 
  box-shadow: 2px 0px 10px rgb(220,220,220);  
  /* margin-top: 200px; */
  background-color: white;
}
.login-button {
  margin: 10px;
}
.title {
  padding: 5px 0px;
  margin: 10px;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 600;
  border-bottom: 2px solid black;
}
.password-field {
  margin: 0px 10px;
  width: 300px !important;
  max-width: 300px;
}
.error {
  color: rgb(200,0,0);
  padding: 0px 15px;
  font-weight: 500;
}
.otp-msg{
  color: green;
  text-align: center;
  padding-bottom: 5px;
}