.row {
  display: flex;
  align-items: center;
  margin: 3px 0px;
}
.field {
  font-weight: 500;
  color: rgb(120,120,120);
  min-width: 200px;
}
.value {
  font-weight: 500;
  color: #144166;
  min-width: 200px;
}