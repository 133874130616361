.create-account {
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
}
.form-container {
  box-shadow: 0px 2px 10px rgb(220,220,220);
  padding: 20px;
  border-radius: 10px;
  width: max-content;
}