.main-container {
    margin:10px;
}

.text-area-input {
    width: 500px !important;
    height: 100px;
}

.text-area-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
}


