.simple-table th.number {
  text-align: center;
}
.td-outer.number {
  width: 100%;
  display: flex;
}
.td-inner.number {
  min-width: 80%;
  text-align: right;
}
.simple-table th {
  border: none;
  background-color: rgb(240,240,240) !important;
}
.simple-table th:first-child {
  border-radius: 8px 0px 0px 8px;
}
.simple-table th:last-child {
  border-radius: 0px 8px 8px 0px;
}
.no-data-message {
  text-align: center;
  color: rgb(200,200,200);
}