
.wallet-container{
    width: 80%;
    border: 1px solid black;
    border-radius: 5px;
    box-shadow: 1px 2px grey;
    position: absolute;
    width: 100%;
    margin-top: 20px;
    text-align: center;
}
.title-header{
    width: 100%;
    text-align: center;
    font-size: 32px;
    font-weight: 800;
    padding-bottom: 15px;
    color: rgb(0,158,152);
}
.wallet-title{
    width: 100%;
    text-align: center;
    font-size: 20px;
    font-weight: 600;
    padding-bottom: 10px;
    color: #144166;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 32px;
}
.loader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Dark overlay */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure modal is above all other elements */
}

.modal {
    background: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    text-align: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1001; /* Above overlay */
    position: relative;
    transform: translateY(-10px); /* Small animation-like effect */
}

.input {
    width: 80%;
    padding: 8px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.button-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.action-button {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background: #007bff;
    color: white;
    cursor: pointer;
    font-size: 14px;
}

.action-button:hover {
    background: #0056b3;
}

.close-button {
    margin-top: 20px;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    background: #ff4d4d;
    color: white;
    cursor: pointer;
}

.toast {
    position: fixed;
    top: 20px;
    right: 20px;
    color: white;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    z-index: 1100;
    font-size: 14px;
}

.toast-success {
    background: #4caf50; /* Green for success */
}

.toast-error {
    background: #f44336; /* Red for error */
}