.customer-details {
    padding: 15px;
  }
  .search-form {
    display: flex;
    padding: 10px 0px;
  }
  .row {
    display: flex;
    align-items: center;
    margin: 3px 0px;
  }
  .info-card {
    background-color: rgb(245,245,245);
    border-radius: 8px;
    padding: 15px;
    margin: 10px 0px;
  }
  .field {
    font-weight: 500;
    color: rgb(120,120,120);
    min-width: 140px;
  }
  .value {
    font-weight: 500;
    color: #144166;
  }
  .toggle-button {
    margin-left: 15px;
    padding: 0px;
    border: none;
    background-color: transparent;
    height: max-content;
  }
  .deleted-alert {
    font-size: 1.2rem;
    background-color: red;
    color: #fff;
    font-weight: bold;
    padding: 10px;
    border-radius: 8px;
  }

  .add-pass-modal-header {
    margin-bottom: 10px;
  }

  .add-pass-input {
    justify-content: space-between;
  }