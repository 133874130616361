.dashboard {
  display: flex;
}
.main-content {
  width: 100%;
  height: 100vh;
  overflow: auto;
}
.side-bar {
  width: 300px;
  height: 100vh;
  background-color: white;
  border-right: 1px solid rgb(230,230,230);
  box-shadow: 0px 20px 20px rgb(240,240,240); 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.tab {
  padding: 6px 15px;
  font-weight: 500;
  border-bottom: 1px solid rgb(240,240,240);
  cursor: pointer;
  border-left: 5px solid transparent;
  color: rgb(120,120,120);
}
.tab:hover {
  color: #144166;
}
.tab.active {
  color: #144166;
  background-color: rgb(240,240,240);
  border-left: 5px solid #144166;
}
.dashboard-title {
  padding: 15px;
  line-height: 110%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* text-align: center; */
  font-size: 16px;
  color: #60feff;
  background-color: #144166;
  border-bottom: 1px solid rgb(240,240,240);
  font-weight: 700;
}
.dashboard-title img {
  width: 60%;
  margin-top: 5px;
}
.dashboard-title div {
  color: #21548a;
  font-size: 16px;
  border-radius: 4px;
  width: max-content;
}
.logout-button-container {
  display: flex;
  padding: 20px;
}
.logout-button {
  width: 100%;
  height: max-content;
  padding: 8px;
  border-radius: 8px;
  background-color: transparent !important;
  color: rgb(200,0,80) !important;
  border: 1px solid rgb(200,0,80) !important;
}
.logout-button:hover {
  color: white !important;
  background-color: rgb(200,0,80) !important;
}
