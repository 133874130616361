@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.regular-button {
  background-color: #144166 !important;
  color: white !important;
  border: 1px solid #144166 !important;
  box-shadow: none !important;
}
.regular-button:active {
  opacity: 0.8;
}
.page-heading {
  font-size: 20px;
  line-height: 100%;
  color: rgb(0,158,152);
  font-weight: 500;
  width: max-content;
  padding-bottom: 5px;
  border-bottom: 2px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(to right, rgb(0,158,152) 40%, transparent 20%);
  margin-bottom: 10px;
}
.page-heading.secondary {
  color: black;
  font-size: 18px;
  border-image-source: linear-gradient(to right,black 40%, transparent 20%);
}
.input-label {
  color: rgba(15, 30, 61, 0.4);
  font-weight: 600;
  padding: 5px 2px;
  line-height: 100%;
  margin-left: 2px;
}
.input-label.active {
  color: #144166;
}
.radio-group {
  display: flex;
}
.radio-input {
  display: flex;
  margin-right: 20px;
  align-items: center;
  cursor: pointer;
}
.regular-radio-button {
	-webkit-appearance: none;
	border: 2px solid rgba(15, 30, 61, 0.2);
	padding: 6px;
	display: inline-block;
  position: relative;
  font-weight: bold;
  border-radius: 50%;
}
.regular-radio-button:checked {
	background-color: #144166;
  border: 2px solid #144166;
}
.regular-radio-button:checked:after {
	content: '✓';
	font-size: 10px;
  position: absolute;
  left:1px;
  bottom: -2px;
	color: white;
}
.pdf-modal .modal-content {
  width: 850px !important;
  right: 118px;
}

.modal-content {
  width: max-content !important;
  padding: 0px 40px;
}
.modal-dialog-centered {
  justify-content: center;
}
.regular-select {
  width: 100%;
}
